/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { isEmpty } from '@ember/utils';
import type Router from '@ember/routing/router-service';
import IntercomTimezoneOptions from 'embercom/lib/intercom-timezone-options';

interface Timezonepayload {
  name: string;
  description: string;
  value: string;
}

export default class CustomReportsService extends Service {
  @service declare appService: any;
  @service declare router: Router;
  @service declare permissionsService: any;

  get fallback(): Record<string, string> {
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    return { 'Europe/Kiev': 'Europe/Kyiv' };
  }

  get canChangeCustomReports() {
    if (this.appService.app.canShareReportsInternally) {
      return this.permissionsService.currentAdminCan('can_reporting__reports__update');
    }
    return this.permissionsService.currentAdminCan('can_reporting__custom_reports__change');
  }

  loadChangePermissionModal() {
    if (this.appService.app.canShareReportsInternally) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_reporting__reports__update',
      );
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_reporting__custom_reports__change',
      );
    }
  }

  @action
  async handleCreateReport() {
    if (!this.canChangeCustomReports) {
      this.loadChangePermissionModal();
    } else {
      this.router.transitionTo('apps.app.reports.custom-reports.report.new');
    }
  }

  timezones() {
    let momentTimezones = moment.tz.names();
    if (isEmpty(momentTimezones)) {
      return IntercomTimezoneOptions;
    }

    let formattedTimezones: Timezonepayload[] = [];
    let descriptions: string[] = [];
    momentTimezones.forEach((timezoneValue: string) => {
      let formattedName = timezoneValue
        .slice(timezoneValue.lastIndexOf('/') + 1)
        .replace(/_/g, ' ');
      let offset = moment
        .tz(timezoneValue)
        .format('[GMT]Z')
        .replace(/0(\d:)/, '$1')
        .replace(':00', '');

      let formattedDescription = this.formatDescription(offset, timezoneValue);
      if (!descriptions.includes(formattedDescription)) {
        descriptions.push(formattedDescription);
        formattedTimezones.push({
          name: formattedName,
          description: formattedDescription,
          value: timezoneValue,
        });
      }
    });
    return formattedTimezones;
  }

  formatDescription(offset: string, timezoneValue: string) {
    let fallbackValue = this.fallback[timezoneValue];
    return fallbackValue ? `(${offset}) ${fallbackValue}` : `(${offset}) ${timezoneValue}`;
  }

  get canDeleteReport() {
    if (this.appService.app.canShareReportsInternally) {
      return this.permissionsService.currentAdminCan('can_reporting__reports__delete');
    }
    return this.permissionsService.currentAdminCan('can_reporting__custom_reports__delete');
  }

  loadDeletePermissionsModal() {
    if (this.appService.app.canShareReportsInternally) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_reporting__reports__delete',
      );
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_reporting__custom_reports__delete',
      );
    }
  }

  generateID() {
    return `new-${new Date().valueOf()}`;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'custom-reports-service': CustomReportsService;
  }
}

declare module '@ember/service' {
  interface Registry {
    customReportsService: CustomReportsService;
    'custom-reports-service': CustomReportsService;
  }
}
