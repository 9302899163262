/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { restartableTask, enqueueTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';
import type ReportAccessControlList from 'embercom/models/reporting/report-access-control-list';
import type Store from '@ember-data/store';
import type Report from 'embercom/models/reporting/custom/report';
import ReportAccessRequestModal from 'embercom/components/reporting/custom/report-access-request-modal';
import { isEmpty } from 'underscore';

export enum AccessType {
  RESTRICTED_VIEW = 'restricted_view',
  VIEW = 'view',
  EDIT = 'edit',
}

export default class ReportAccessService extends Service {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;
  @service declare permissionsService: any;
  @service declare modalService: any;
  @service declare router: any;
  @service declare intercomEventService: any;

  @tracked adminAccess: ReportAccessControlList[] = [];

  sharedAnalyticsData = {
    object: 'report_access',
    section: 'reports',
  };

  constructor() {
    super(...arguments);
  }

  get app() {
    return this.appService.app;
  }

  //task to get access from then backend for the current user
  async loadAdminReportAccess() {
    return taskFor(this.fetchAdminReportAccess).perform();
  }

  get loadAdminReportAccessIsRunning() {
    return taskFor(this.fetchAdminReportAccess).isRunning;
  }

  @enqueueTask
  *fetchAdminReportAccess(): TaskGenerator<void> {
    // using query here to ensure that when we load model
    // if teammate on another browser has deleted access control
    //  we do not return them from store.
    // we only return exact response from backend. not backendResponse + dataInStore
    // reason here https://github.com/intercom/intercom/issues/354091#issuecomment-2337524088
    this.adminAccess = yield this.store.query('reporting/report_access_control_list', {});
  }

  //task to get access from then backend for a specific report
  async loadReportAccess(reportId: string) {
    return taskFor(this.fetchReportAccess).perform(reportId);
  }

  get reportAccess() {
    return taskFor(this.fetchReportAccess).lastSuccessful?.value || [];
  }

  @enqueueTask
  *fetchReportAccess(reportId: string): TaskGenerator<ReportAccessControlList[]> {
    return yield this.store.query('reporting/report-access-control-list', {
      report_id: reportId,
    });
  }

  // current report access for an admin
  currentAdminReportAccess(reportId: string): ReportAccessControlList | undefined {
    return this.adminAccess.find((access) => {
      return access.reportId === reportId && access.adminId === this.appService.app.currentAdmin.id;
    });
  }

  currentReportWorkspaceAccess(reportId: string): ReportAccessControlList | undefined {
    return this.adminAccess.find((control) => {
      return control.isWorkspaceAccessControl && control.reportId === reportId;
    });
  }

  @restartableTask
  *updateReportAccess(report: Report, accessControls: ReportAccessControlList[]) {
    let accessControlsWithoutOwner = accessControls.filter(
      (control) => control.adminId !== report.createdById,
    );
    yield post('/ember/reporting/report_access_control_list/bulk_update', {
      app_id: this.appService.app.id,
      report_id: report.id,
      controls: accessControlsWithoutOwner.map((control) => {
        return {
          admin_id: control.adminId,
          access_type: control.accessType,
        };
      }),
    });

    accessControls.forEach((control: ReportAccessControlList) => {
      if (control.get('isNew')) {
        this.intercomEventService.trackAnalyticsEvent({
          ...this.sharedAnalyticsData,
          action: 'created',
          report_id: report.id,
          is_workspace: control.isWorkspaceAccessControl,
          access_type: control.accessType,
        });
      } else if (control.get('hasDirtyAttributes')) {
        this.intercomEventService.trackAnalyticsEvent({
          ...this.sharedAnalyticsData,
          action: 'changed',
          report_id: report.id,
          is_workspace: control.isWorkspaceAccessControl,
          from: control.changedAttributes().accessType?.[0],
          to: control.accessType,
        });
      }
    });
  }

  adminHasEditAccess(report: Report) {
    return (
      report.createdById === this.appService.app.currentAdmin.id ||
      this._highestAccessLevel(report) === AccessType.EDIT
    );
  }

  adminHasViewAccess(report: Report) {
    let access = this._highestAccessLevel(report);

    return access === AccessType.VIEW || access === AccessType.EDIT;
  }

  adminHasRestrictedAccess(report: Report) {
    return this._highestAccessLevel(report) === AccessType.RESTRICTED_VIEW;
  }

  adminHasAccess(report: any) {
    return (
      (this.currentAdminReportAccess(report?.id) ||
        report?.createdById === this.appService.app.currentAdmin.id) &&
      report !== null
    );
  }

  loadReportAccessModal(reportId: string, onClose: () => void) {
    this.modalService.openModal(ReportAccessRequestModal, {
      reportId,
      onClose,
    });
  }

  _highestAccessLevel(report: Report) {
    let access = this.currentAdminReportAccess(report.id);
    let workspaceAccess = this.currentReportWorkspaceAccess(report.id);

    if (!access && !workspaceAccess) {
      return undefined;
    }

    if (
      access?.accessType === AccessType.EDIT ||
      workspaceAccess?.accessType === AccessType.EDIT ||
      report.createdById === this.appService.app.currentAdmin.id
    ) {
      return AccessType.EDIT;
    } else if (
      access?.accessType === AccessType.VIEW ||
      workspaceAccess?.accessType === AccessType.VIEW
    ) {
      return AccessType.VIEW;
    } else {
      return AccessType.RESTRICTED_VIEW;
    }
  }

  canEditReportOrReportIsNew(report: Report) {
    if (this.appService.app.canShareReportsInternally && report.isIntercomOwnedReport) {
      return this.adminHasEditAccess(report);
    } else if (this.appService.app.canShareReportsInternally) {
      return this.adminHasEditAccess(report) || report.isNew || isEmpty(report.createdById);
    } else {
      return true;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    reportAccessService: ReportAccessService;
    'report-access-service': ReportAccessService;
  }
}
