/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { A } from '@ember/array';
import { action, computed } from '@ember/object';
import { notEmpty, readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { task, waitForProperty } from 'ember-concurrency';
import { fetchAdminPageviews, markViewAsSeen } from 'embercom/lib/admin-view-tracking';
import ajax, { get } from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import moment from 'moment-timezone';

const ADMIN_PAGEVIEW_KEY_PREFIX = 'onboarding_home_level';

export default Service.extend({
  notificationsService: service(),
  store: service(),
  router: service(),
  intl: service(),
  appService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),
  guide: null,
  levelAdminPageViews: A(),
  incompleteAndUnseenLevels: computed(
    'levelAdminPageViews.[]',
    'guide.incompleteLevels.[]',
    function () {
      if (!this.guide) {
        return A();
      }
      return this.get('guide.incompleteLevels').reject((level) =>
        this._levelPageViewPresentFor(level.identifier),
      );
    },
  ),

  hasIncompleteAndUnseenLevels: notEmpty('incompleteAndUnseenLevels'),
  foundationalLevel: computed('guide.levels.[]', function () {
    let levels = this.get('guide.levels');
    return levels && levels.length ? levels.find((level) => level.levelData?.isFoundational) : null;
  }),

  showUnseenLevelBadge: computed('app.created_at', 'hasIncompleteAndUnseenLevels', function () {
    let now = moment();
    let appCreatedPlus6Months = moment(this.app.created_at).add(6, 'M');
    return this.hasIncompleteAndUnseenLevels && now.isBefore(appCreatedPlus6Months);
  }),

  init() {
    this._super(...arguments);
    this.setLevelAdminPageViews.perform();
  },

  setLevelAdminPageViews: task(function* () {
    let allPageViews = yield fetchAdminPageviews(this.store);
    let levelPageViews = allPageViews.filter((pageView) =>
      pageView.pageKey.startsWith(ADMIN_PAGEVIEW_KEY_PREFIX),
    );
    this.set('levelAdminPageViews', levelPageViews);
  }),

  createPageViewForLevel: task(function* (levelIdentifier, eventName = null) {
    yield waitForProperty(this, 'setLevelAdminPageViews.isIdle');

    if (eventName) {
      this.intercomEventService.trackEvent(eventName);
    }

    if (!this._levelPageViewPresentFor(levelIdentifier)) {
      let adminPageView = yield markViewAsSeen(
        this.store,
        this._pageViewKeyForLevel(levelIdentifier),
      );
      this.levelAdminPageViews.pushObject(adminPageView);
    }
  }),

  showContentIngestionToastIfSetInStorage() {
    let shouldShowContentIngestionToast = localStorage.getItem('showContentIngestionToast');
    if (shouldShowContentIngestionToast) {
      this.showContentIngestionToast();
      localStorage.removeItem('showContentIngestionToast');
    }
  },

  showContentIngestionToast() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('onboarding.home.notification.content-ingestion-started'),
      20000,
    );
  },

  hasWizardsInProgress() {
    return (
      this.guide?.levels.filter(
        (level) =>
          (level.isInProgress || level.completionProgress > 0) &&
          level.identifier !== 'guide_library_foundational_steps' &&
          level.identifier !== 'guide_library_foundational_steps_v2',
      ).length > 0
    );
  },

  async loadGuide(loadSteps = true) {
    let appId = this.app?.id;
    if (!appId) {
      return;
    }

    let guide = await this.store.findRecord('onboarding/home/guide', appId);
    if (loadSteps) {
      try {
        await guide.loadSteps();
      } catch (e) {
        console.error('Error loading steps');
        captureException(e);
      }
    }
    this.set('guide', guide);
  },

  async findOrLoadGuide(loadSteps = true) {
    let guide = this.guideForCurrentAppFromStore();
    if (guide) {
      return this.set('guide', guide);
    }

    return await this.loadGuide(loadSteps);
  },

  async resetGuide(options = {}) {
    await ajax({
      url: '/ember/onboarding/home/guide/reset',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.get('app.id'),
        levels: options.levels,
        steps: options.steps,
      }),
    });
    await this.loadGuide();
  },

  async getAvailableGuides() {
    return await get('/ember/onboarding/home/guide/available_guides', {
      app_id: this.get('app.id'),
    });
  },

  async changeGuide({ identifier }) {
    return await ajax({
      url: '/ember/onboarding/home/guide/change_guide',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.get('app.id'),
        identifier,
      }),
    });
  },

  guideForCurrentAppFromStore() {
    let appId = this.app?.id;
    if (!appId) {
      return null;
    }

    return this.store.peekRecord('onboarding/home/guide', appId);
  },

  getStep: action(function (identifier) {
    let steps = this.store.peekAll('onboarding/home/step');
    return steps.findBy('identifier', identifier);
  }),

  openMessengerInstallationStep() {
    let step = this.get('guide.installMessengerStep');

    if (step) {
      return this.router.transitionTo('apps.app.home', this.app, {
        queryParams: { step: step.identifier },
      });
    }
    this.router.transitionTo('apps.app.settings.channels.messenger.web');
  },

  messengerInstallationStepUrl() {
    let step = this.get('guide.installMessengerStep');

    if (step) {
      return this.router.urlFor('apps.app.home', this.app, {
        queryParams: { step: step.identifier },
      });
    }
    return this.router.urlFor('apps.app.settings.channels.messenger.web');
  },

  _levelPageViewPresentFor(levelIdentifier) {
    return this.levelAdminPageViews.any((pageview) => {
      return pageview.pageKey === this._pageViewKeyForLevel(levelIdentifier);
    });
  },

  _pageViewKeyForLevel(levelIdentifier) {
    return [ADMIN_PAGEVIEW_KEY_PREFIX, levelIdentifier].join('_');
  },
});
