/* RESPONSIBLE TEAM: team-frontend-tech */

import Service from '@ember/service';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';

export default class ReactService extends Service {
  @tracked didBootFail = false;

  @action setBootFailed() {
    this.didBootFail = true;
  }
}

declare module '@ember/service' {
  interface Registry {
    reactService: ReactService;
    'react-service': ReactService;
  }
}
