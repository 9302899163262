/* RESPONSIBLE TEAM: team-purchase-experience */

import Service, { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { camelize } from '@ember/string';
import { task } from 'ember-concurrency-decorators';
import { captureException } from 'embercom/lib/sentry';
import { type TaskGenerator } from 'ember-concurrency';

export const DEFAULT_ANNUAL_CHECKOUT_EXPERIMENT = 'DefaultAnnualCheckoutExperiment';
export const PHONE_FREE_USAGE_EXPERIMENT = 'PhoneFreeUsageExperiment';

enum VALID_EXPERIMENT_NAMES {
  DefaultAnnualCheckoutExperiment,
  PhoneFreeUsageExperiment,
}

// Used to check flags on app
// We can then tell if app has already been opted in before making api request
const FEATURE_FLAGS_FOR_EXPERIMENTS = {
  DefaultAnnualCheckoutExperiment: [
    'teamPurchaseExperienceCheckoutDefaultMonthly',
    'teamPurchaseExperienceCheckoutDefaultAnnual',
  ],
  PhoneFreeUsageExperiment: ['team-phone-free-usage-control', 'team-phone-free-usage-variant'],
};

export default class ExperimentOptInService extends Service {
  @service appService: any;

  @task
  *registerForExperiment(experimentName: keyof typeof VALID_EXPERIMENT_NAMES): TaskGenerator<void> {
    if (this.previouslyOptedIn(experimentName)) {
      return;
    }
    try {
      let res = yield ajax({
        url: `/ember/experiment_opt_ins/register`,
        type: 'POST',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          experiment: { name: experimentName },
        }),
      });
      let { control, variant } = res;
      this.appService.app.set(camelize(control['name']), control['opted_in']);
      this.appService.app.set(camelize(variant['name']), variant['opted_in']);
    } catch (e) {
      captureException(e);
    }
  }

  previouslyOptedIn = (experimentName: keyof typeof VALID_EXPERIMENT_NAMES) => {
    let allFlagsForExperiment = FEATURE_FLAGS_FOR_EXPERIMENTS[experimentName];
    return allFlagsForExperiment.any((flag: string) => this.appService.app[flag]);
  };
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'experiment-opt-in-service': ExperimentOptInService;
  }
}
